<template>
  <header class="app-header"></header>
</template>
<script>
// import Slider from '@/components/Slider'
import axios from "axios";
export default {
  name: "AppTopnav",
  data() {
    return {
      activeIndex: "1",
      category: [],
      cardData: [],
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    getCategoryAction() {
      const url = process.env.VUE_APP_BASE_API + "/model/queryAll";
      axios({
        // 请求使用的方法，可写get或者post等
        method: "GET",
        // url写的是请求地址
        url: url,
        // 以下都是固定写法
      }).then((res) => {
        // 接口请求成功的一个标志，200是状态码，双等号前面不一定写res.data.code，
        // 这个写法是根据后端接口返回数据决定，code的值会告诉你。
        if (res.data.code === 2000) {
          // 可以打印看看传过来的值
          // 等号左侧this.xxxx
          // xxxx代表个人对 由页面中需要用到的数据组成的数组的集合 起的名字
          // 右侧res.data.xxxx   xxxx是后端传过来的数组的名字
          this.category = res.data.data;
          console.log(this.category);
          this.category.forEach((item) => {
            if (item.cardVoList) {
              this.cardData = item.cardVoList;
              console.log(this.cardData);
            }
          });
          // console.log(this.cardData)
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
  },
  mounted() {
    // this.getCategoryAction()
  },
};
</script>
<style scoped lang='less'>
.app-header {
  background: #fff;
  position: relative;
  .first {
    position: absolute;
    right: 190px;
    top: 15px;
  }
  .container {
    display: flex;
    align-items: center;
  }
  .logo {
    width: 200px;
    a {
      display: block;
      height: 132px;
      width: 100%;
      text-indent: -9999px;
      // background: url('@/assets/images/logo.png') no-repeat center 18px /
      // contain;
    }
  }

  .app-header-nav {
    width: 1820px;
    display: flex;
    padding-left: 40px;
    position: relative;
    z-index: 998;

    li {
      // margin-right: 40px;
      width: 200px;
      text-align: center;

      a {
        font-size: 16px;
        line-height: 32px;
        height: 32px;
        display: inline-block;
      }
    }
  }

  .search {
    width: 170px;
    height: 32px;
    position: relative;
    // border-bottom: 1px solid #e7e7e7;
    line-height: 32px;

    .icon-search {
      font-size: 18px;
      margin-left: 5px;
    }

    input {
      width: 140px;
      padding-left: 5px;
      color: #666;
    }
  }

  .cart {
    width: 50px;

    .curr {
      height: 32px;
      line-height: 32px;
      text-align: center;
      position: relative;
      display: block;

      .icon-cart {
        font-size: 22px;
      }

      em {
        font-style: normal;
        position: absolute;
        right: 0;
        top: 0;
        padding: 1px 6px;
        line-height: 1;
        background: @helpColor;
        color: #fff;
        font-size: 12px;
        border-radius: 10px;
        font-family: Arial;
      }
    }
  }
}
</style>
