<template>
  <!-- 顶部通栏组件 -->
  <!-- <app-topnav /> -->
  <!-- 头部组件 -->
  <div>
    <TopHeader />
    <mainContent />
    <!-- 底部footer -->
    <LayoutFooter />
  </div>
</template>

<script>
import LayoutFooter from './components/app-footer.vue'
import TopHeader from './components/layout-header.vue'
import mainContent from './components/main-content'
export default {
  name: 'XtxLayout',
  components: {
    LayoutFooter,
    TopHeader,
    mainContent
  }
}
</script>

<style scoped lang="less">
</style>
