import Vue from 'vue'
import VueRouter from 'vue-router'
import category from '@/views/category'
// import HomeView from '../views/HomeView.vue'
// 导入vuex实例
// import store from '@/store'
// import request from '@/utils/request'

import Layout from '@/views/Layout'
import Home from '@/views/home'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },
      {
        path: 'category/:id',
        name: 'category',
        component: category
      }
    ]
  }
]
// 创建路由实例
const router = new VueRouter({
  routes
})

export default router
