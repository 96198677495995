<template>
  <footer class="app_footer">
    <!-- 联系我们 -->
    <!-- 其它 -->
    <div class="extra">
      <div class="container">
        <div class="slogan" style="text-align: center">
          <a href="javascript:;">
            <i class="iconfont icon-footer01"></i>
            <span
              ><img
                style="height: 600px"
                src="../../../assets/image.png"
                alt=""
            /></span>
          </a>
          <div
            style="
              color: #fff;
              text-align: left;
              margin-left: -300px;
              margin-top: 10px;
            "
          >
            <p>地址 : 北京市东城区青年湖北里国家电投综合楼</p>
            <!-- <p>电话 : 010-67796881</p> -->
            <p>邮箱 : chenkuo@ck2015.com</p>
          </div>
        </div>
        <!-- 版权信息 -->
        <div class="copyright">
          <el-link
            type="primary"
            :underline="false"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            ><p>
              CopyRight ©2022-2024
              <i style="padding-left: 50px">京ICP备2024048516号-1</i>
              <i style="padding-left: 50px">辰阔数字科技有限公司版权所有</i>
            </p></el-link
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang='less'>
.app_footer {
  overflow: hidden;
  background-color: #f5f5f5;
  padding-top: 20px;
  .contact {
    background: #fff;
    .container {
      padding: 60px 0 40px 25px;
      display: flex;
    }
    dl {
      height: 190px;
      text-align: center;
      padding: 0 72px;
      border-right: 1px solid #f2f2f2;
      color: #999;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
    dt {
      line-height: 1;
      font-size: 18px;
    }
    dd {
      margin: 36px 12px 0 0;
      float: left;
      width: 92px;
      height: 92px;
      padding-top: 10px;
      border: 1px solid #ededed;
      .iconfont {
        font-size: 36px;
        display: block;
        color: #666;
      }
      &:hover {
        .iconfont {
          color: @xtxColor;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .qrcode {
      width: 92px;
      height: 92px;
      padding: 7px;
      border: 1px solid #ededed;
    }
    .download {
      padding-top: 5px;
      font-size: 14px;
      width: auto;
      height: auto;
      border: none;
      span {
        display: block;
      }
      a {
        display: block;
        line-height: 1;
        padding: 10px 25px;
        margin-top: 5px;
        color: #fff;
        border-radius: 2px;
        background-color: @xtxColor;
      }
    }
    .hotline {
      padding-top: 20px;
      font-size: 22px;
      color: #666;
      width: auto;
      height: auto;
      border: none;
      small {
        display: block;
        font-size: 15px;
        color: #999;
      }
    }
  }
  .extra {
    background-color: #333;
  }
  .slogan {
    height: 150px;
    line-height: 30px;
    padding: 30px 80px;
    border-bottom: 1px solid #434343;
    display: flex;
    justify-content: space-around;
    p {
      line-height: 1;
      margin-bottom: 20px;
    }
    a {
      color: #999;
      line-height: 1;
      padding: 0 7rem;
      border-right: 1px solid #999;
      &:last-child {
        border-right: none;
      }
    }
    a {
      height: 100px;
      line-height: 58px;
      color: #fff;
      font-size: 15px;
      i {
        font-size: 50px;
        vertical-align: middle;
        margin-right: 10px;
        font-weight: 100;
      }
      span {
        vertical-align: middle;
        text-shadow: 0 0 1px #333;
      }
    }
  }
  .copyright {
    height: 100px;
    padding-top: 50px;
    text-align: center;
    color: #999;
    font-size: 15px;
    p {
      line-height: 1;
      margin-bottom: 20px;
    }
    a {
      color: #999;
      line-height: 1;
      padding: 0 10px;
      border-right: 1px solid #999;
      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
