/**
 * 用户模块：存储token、个人信息
 */
export default {
  namespaced: true,
  // 1.定义变量
  state: {
  },
  // 修改变量(同步)
  mutations: {

  },
  // 2.发请求(异步)
  actions: {

  }
}
