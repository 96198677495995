<template>
  <div>
    <!-- 头部div -->
    <Top />
  </div>
</template>

<script>
import Top from './components/top.vue'
export default {
  name: 'home-vue',
  components: {
    Top
  }
}
</script>

<style lang="less" scoped>
</style>
