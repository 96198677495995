<template>
  <div>safdsefresftrewterttttttttttttttttttttttttttttttttttttttttttt</div>
</template>

<script>
export default {
  name: 'top-vue'
}
</script>

<style lang="less" scoped>
</style>
