<template>
  <div class="card">
    <div class="container" style="background: #fff">
      <img
        style="height: 48px; margin-top: 15px"
        src="../../../assets/image(1).svg"
        alt=""
      />
      <!-- <h3 style="width: 300px; color: #fff">辰阔数字科技有限公司</h3> -->
      <div class="Menu">
        <el-menu
          :default-active="activeIndex + ''"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          text-color="#000"
          active-text-color="#00a2e8"
        >
          <template v-for="item in category">
            <el-menu-item
              :key="item.id"
              :index="item.id + ''"
              v-if="item.dataSon.length === 0"
            >
              <template
                slot="title"
                v-if="item.linkUrl !== null && item.linkUrl !== ''"
              >
                <el-link
                  type="primary"
                  :underline="false"
                  :href="item.linkUrl"
                  style="color: #000"
                  target="_blank"
                  >{{ item.name }}
                </el-link>
              </template>
              <template v-else slot="title">
                <span @click="querymodelhandler(item.id)">{{ item.name }}</span>
              </template>
            </el-menu-item>

            <el-submenu
              :key="item.id"
              :index="item.id + ''"
              v-if="item.dataSon.length > 0"
            >
              <template slot="title">
                <span
                  @click="querymodelhandler(item.id)"
                  :class="item.id === activeIndex ? 'activeclickhandler' : ''"
                >
                  {{ item.name }}
                </span>
              </template>

              <el-menu-item
                v-for="sunItem in item.dataSon"
                :index="sunItem.id + ''"
                :key="sunItem.id"
              >
                <el-link
                  v-if="sunItem.linkUrl !== null"
                  style="color: #000"
                  type="primary"
                  :underline="false"
                  :href="sunItem.linkUrl"
                  target="_blank"
                  >{{ sunItem.name }}
                </el-link>
                <span v-else @click="querymodelhandler(sunItem.id)">
                  {{ sunItem.name }}</span
                >
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>

    <div v-if="cardData.length > 0">
      <div
        style="text-align: center; margin-top: 2rem; margin-bottom: 5px"
        v-for="item in cardData"
        :key="item.id"
      >
        <!-- 1.轮播图 -->
        <el-carousel
          :interval="5000"
          arrow="always"
          v-if="item.cardType === 'banner'"
          height="180px"
        >
          <el-carousel-item v-for="i in item.contentVoList" :key="i.id">
            <!-- <h3>{{ i.content }}</h3> -->
            <img
              :src="i.content"
              alt=""
              @click="jumpAction(i.url)"
              class="image"
            />
          </el-carousel-item>
        </el-carousel>
        <!-- 2.图片 -->

        <div v-if="item.cardType === 'picter'">
          <img
            :src="i.content"
            alt=""
            v-for="i in item.contentVoList"
            :key="i.id"
            @click="jumpAction(i.url)"
          />
        </div>
        <!-- 3.上图下字 -->
        <div class="TpBt" v-if="item.cardType === 'TpicterBText'">
          <!-- <el-container
            v-for="i in item.contentVoList"
            :key="i.id"
            class="TBfather"
          >
            <el-main v-if="i.contentType === '文件'"
              ><img :src="i.content" alt="" style="height: 180px; width: 300px"
            /></el-main>
            <el-footer style="width: 300px" v-else class="TBSon">{{
              i.content
            }}</el-footer>
          </el-container> -->

          <el-container
            v-for="i in item.topPriceDownFontList"
            :key="i.id"
            style="width: 33.3%"
          >
            <el-main v-if="i.top !== null"
              ><img
                :src="i.top.content"
                alt=""
                style="width: 100%; height: 100%"
            /></el-main>
            <el-footer
              :title="i.down.content"
              v-if="i.down !== null"
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
              >{{ i.down.content }}</el-footer
            >
          </el-container>
        </div>
        <!-- 4.左图右字 -->
        <div
          class="LpRt"
          v-if="item.cardType === 'LpicterRText'"
          style="margin: 100px 13rem 0px 13rem; height: 45rem"
        >
          <!-- <div
            class="content123"
            v-for="i in item.contentVoList"
            :key="i.id"
            style="display: flex"
          >
            <img
              v-if="i.contentType === '文件'"
              :src="i.content"
              alt=""
              style="width: 500px"
            />
            <div class="right" v-if="i.contentType === '文本'">
              {{ i.content }}
            </div>
            <p
              style="margin-left: 550px; max-width: 500px; overflow: auto"
              v-if="i.contentType === '文本'"
            >
              {{ i.content }}
            </p>
          </div>  -->
          <el-container
            v-for="i in item.leftPicterRightText"
            :key="i.id"
            class="LRfather"
            style="height: 45rem"
          >
            <el-aside style="height: 100%; width: 50%"
              ><img
                style="height: 100%; width: 100%"
                :src="i.left.content"
                alt=""
            /></el-aside>

            <el-main
              style="
                width: 50%;
                height: 100%;
                word-wrap: break-word;
                line-height: 2rem;
                text-align: left;
              "
            >
              <div style="height: 10rem" v-if="i.rightOne">
                {{ i.rightOne.content }}
              </div>
              <div style="height: 10rem" v-if="i.rightTwo">
                {{ i.rightTwo.content }}
              </div>
              <div style="height: 10rem; margin-top: 5rem" v-if="i.rightThree">
                {{ i.rightThree.content }}
              </div>
            </el-main>

            <!-- <el-main v-if="i.rightTwo" style="width: 300px';overflow:hidden">{{
              i.rightTwo.content
            }}</el-main>

            <el-main
              v-if="i.rightThree"
              style="width: 300px';overflow:hidden"
              >{{ i.rightThree.content }}</el-main
            > -->
          </el-container>
        </div>

        <!-- 富文本编辑器 -->
        <div
          class="fwb"
          v-if="item.cardType === 'Fwbedit'"
          style="margin: 80px 13rem 0px 13rem"
        >
          <el-container
            class="LRfather"
            v-for="i in item.contentVoList"
            :key="i.id"
            v-html="i.content"
          >
            <el-footer style="margin-left: 300px; padding: 30px"></el-footer>
          </el-container>
        </div>
        <!-- 带标题的富文本编辑器 -->
        <div
          class="fwbTitle"
          v-if="item.cardType === 'TtitleBdetail'"
          style="margin: 80px 13rem 0px 13rem"
        >
          <el-container
            class="LRfather"
            v-for="i in item.ttitleBDetailList"
            :key="i.id"
          >
            <span>
              <el-collapse v-model="activeName" accordion>
                <el-collapse-item :title="i.title.content" :name="i.id">
                  <div
                    style="text-align: left; margin-top: 10px"
                    v-html="i.richText.content"
                  ></div>
                </el-collapse-item>
              </el-collapse>
            </span>

            <el-footer style="margin-left: 300px; padding: 30px"></el-footer>
          </el-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Slider from '@/components/Slider'
import axios from "axios";
export default {
  name: "AppTopnav",
  data() {
    return {
      activeIndex: "",
      category: [],
      cardData: [],
      level2menuData: [],
      // 生产
      baseURL: process.env.VUE_APP_BASE_API,
      activeName: "1",
      // 图片父容器高度
      bannerHeight: 1000,
      // 浏览器宽度
      screenWidth: 0,
    };
  },
  created() {
    console.log("level2menuData:", this.level2menuData);
    window.querymodelhandler = this.querymodelhandler;
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    getCategoryAction() {
      const url = this.baseURL + "/model/queryAll";
      axios({
        // 请求使用的方法，可写get或者post等
        method: "GET",
        // url写的是请求地址
        url: url,
        // 以下都是固定写法
      }).then((res) => {
        // 接口请求成功的一个标志，200是状态码，双等号前面不一定写res.data.code，
        // 这个写法是根据后端接口返回数据决定，code的值会告诉你。
        if (res.data.code === 2000) {
          // 可以打印看看传过来的值
          // 等号左侧this.xxxx
          // xxxx代表个人对 由页面中需要用到的数据组成的数组的集合 起的名字
          // 右侧res.data.xxxx   xxxx是后端传过来的数组的名字
          this.category = res.data.data;
          console.log(this.category);

          this.category.forEach((item) => {
            // this.level2menuData = item.dataSon
            // console.log('level2menuData:', this.level2menuData)
            if (item.cardVoList) {
              this.cardData = item.cardVoList;
              if (item.name === "首页") {
                this.activeIndex = item.id;
                console.log(this.activeIndex);
              }
            }
          });
          // console.log(this.cardData)
        } else {
          // this.$message({
          //   message: res.data.data,
          //   type: 'error'
          // })
        }
      });
    },
    querymodelhandler(id) {
      this.activeIndex = id;
      this.cardData = "";
      const url = this.baseURL + "/model/queryByPid?id=" + id;
      axios({
        // 请求使用的方法，可写get或者post等
        method: "GET",
        // url写的是请求地址
        url: url,
        // 以下都是固定写法
      }).then((res) => {
        // 接口请求成功的一个标志，200是状态码，双等号前面不一定写res.data.code，
        // 这个写法是根据后端接口返回数据决定，code的值会告诉你。
        if (res.data.code === 2000) {
          // 可以打印看看传过来的值
          // 等号左侧this.xxxx
          // xxxx代表个人对 由页面中需要用到的数据组成的数组的集合 起的名字
          // 右侧res.data.xxxx   xxxx是后端传过来的数组的名字
          console.log(res.data.data);
          this.cardData = res.data.data;
        } else {
          this.cardData = "";
          // this.$message({
          //   message: res.data.data,
          //   type: 'error'
          // })
        }
      });
      // this.clickhandler = true
    },
    // 带标题的富文本编辑器的点击事件
    TtitleBdetailAction(data) {
      console.log(data);
      this.querymodelhandler(data);
    },
    // 图片跳转页面
    jumpAction(data) {
      console.log(data);
      if (data.length > 0) {
        if (data.length === 4) {
          if (data === "130,") {
            console.log(data);
          } else {
            // 表示是内联链接
            const innerUrl = data.substring(0, 3);
            this.querymodelhandler(innerUrl);
          }
        } else if (data.length > 4) {
          const outerUrl = data.substring(4, data.length);
          window.location.href = outerUrl;
        }
      }
    },
  },
  mounted() {
    this.getCategoryAction();
  },
};
</script>

<style lang="less" scoped>
.card {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}
.container {
  // margin: 0 auto;
  img {
    margin-left: -20px;
  }
  display: flex;
  .Menu {
    margin-left: 25px;
    font-size: 20px;
    .el-menu {
      span {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: 1px solid transparent;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

// 菜单二级字体样式
li,
.el-link {
  font-size: 18px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-header,
.el-footer {
  // background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
  // border-top: 1px solid #333;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.TBfather {
  position: relative;
}

.TBSon {
  position: absolute;
  top: 17rem;
  left: -40rem;
}

/deep/.el-submenu__title i {
  display: none;
  color: #909399;
}

/deep/.activeclickhandler {
  color: rgb(0, 162, 232);
  border-bottom-color: rgb(0, 162, 232);
}

/deep/.el-collapse-item__header {
  font-size: 30px;
  font-weight: 700;
}

/deep/.el-carousel__item {
  background-color: #fff !important;
}
image {
  /*设置图片宽度和浏览器宽度一致*/
  width: 100%;
  height: inherit;
}
</style>
